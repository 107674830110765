<template>
  <div style="position: fixed; width: 100%">
    <el-header style="height: 60px">
      <div class="left-header">
        <img class="topbar-logo" src="@/assets/logo.png" />
        <div class="head-header">
          <img class="name_logo" src="@/assets/name_logo.png" alt="" />
        </div>
      </div>
      <div
        style="
          position: absolute;
          top: 0px;
          right: 60px;
          color: white;
          cursor: pointer;
          display: flex;
          align-items: center;
          height: 60px;
        "
        @click="to_web"
      >
        <div
          style="
            color: white;
            border: 1px solid white;
            font-size: 16px;
            padding: 5px 10px;
            border-radius: 5px;
          "
        >
          <i class="iconfont icon-a-fenzu21" style="margin-right: 10px"></i>
          {{ $t("share.know_1") }}
        </div>
      </div>
      <language class="language-option"></language>
    </el-header>
    <el-collapse-transition v-if="show_file">
      <div v-show="show_top" class="header">
        <div class="topic">
          <div
            style="
              margin: 0 auto;
              overflow: hidden;
              text-overflow: ellipsis;
              width: 100%;
              white-space: nowrap;
              text-align: left;
            "
          >
            {{ topic }}
          </div>
        </div>
        <div class="operate">
          <div class="word" style="cursor: pointer" @click="to_word">
            <i class="iconfont icon-shitu" style="margin-right: 10px"></i
            >{{ $t("share.map") }}
          </div>
          <div v-if="!is_login" class="save">
            <el-button
              type="primary"
              style="text-align: center"
              size="mini"
              @click="to_login"
              >{{ $t("share.share_word") }}</el-button
            >
          </div>
          <div v-else class="save">
            <el-button
              type="primary"
              style="text-align: center"
              size="mini"
              @click="to_login"
              >{{ $t("share.save") }}</el-button
            >
          </div>
          <!-- <div class="save">
            <el-button
              type="primary"
              style="text-align: center"
              size="mini"
              @click="to_login"
              >{{ $t("share.save") }}</el-button
            >
          </div> -->
        </div>
      </div>
    </el-collapse-transition>
    <div v-if="show_file">
      <div v-if="show_top" class="switch_on" @click="switch_on">
        <i class="iconfont icon-fold_up" style="color: #000" />
        {{ $t("switch.PutAway") }}
      </div>
      <div v-if="!show_top" class="switch_off" @click="switch_off">
        <i class="iconfont icon-fold_down" style="color: #000" />
        {{ $t("switch.Expand") }}
      </div>
    </div>
    <div v-if="show_file" id="line_word_wrap" class="line_word_wrap">
      <div class="line_word_content">
        <div class="catalog_wrap">
          <el-tree
            :data="tree_data_line_word"
            :default-expand-all="true"
            :highlight-current="true"
            :expand-on-click-node="false"
            @node-click="handleNodeClick"
          >
            <div slot-scope="{ node, data }">
              <span
                v-show="data.layer < 5"
                style="color: #2a82e4; margin-right: 5px"
                >{{ index_map[data.id] }}</span
              >

              <span
                v-if="see_review || process_review"
                :key="f6"
                :style="high_ligth_review(data)"
                >{{ data.topic }}</span
              >
              <span v-else>{{ data.topic }}</span>
            </div>
          </el-tree>
        </div>

        <div class="resize" :title="$t('addAlert.collapse')"></div>
        <!--        <div class="right">-->
        <div id="line_word_right">
          <el-button
            style="margin-bottom: 10px; margin-left: 42px"
            round
            @click="expand_all($event)"
            >{{ collapse_button_text }}
          </el-button>

          <div class="line_word">
            <el-tree
              id="word_tree"
              ref="myTree"
              :key="f5"
              :data="tree_data_line_word"
              node-key="id"
              :current-node-key="current_key"
              default-expand-all
              :expand-on-click-node="false"
            >
              <div
                :id="data.id"
                slot-scope="{ node, data }"
                style="width: 100%; margin: 10px 0"
              >
                <div style="display: flex; align-items: center">
                  <el-tooltip
                    effect="dark"
                    :content="data.topic"
                    :disabled="is_show_topic"
                  >
                    <div
                      class="node_topic"
                      @click="show_detail(data, false)"
                      @mouseenter="visibilityChange($event)"
                    >
                      <span>
                        {{ data.topic }}
                      </span>
                    </div>
                  </el-tooltip>
                </div>
                <div
                  v-show="show_detail_map[data.id] || false"
                  class="custom-tree-node"
                >
                  <div
                    :style="
                      'width:' +
                      (data.architectureDiagrams &&
                      data.architectureDiagrams.length > 0
                        ? '50%'
                        : '80%')
                    "
                  >
                    <div class="p1">{{ $t("share.des") }}</div>

                    <div v-if="data.description.length > 0" class="desc">
                      <div
                        class="desc_content"
                        :style="is_over3 ? 'filter: blur(5px);' : ''"
                        v-html="data.description"
                      ></div>
                    </div>
                    <div v-else class="desc">
                      <div
                        class="desc_content"
                        :style="is_over3 ? 'filter: blur(5px);' : ''"
                      >
                        {{ $t("share.nodata") }}
                      </div>
                    </div>
                  </div>
                  <div
                    :style="
                      'width:' +
                      (data.architectureDiagrams &&
                      data.architectureDiagrams.length > 0
                        ? '50%'
                        : '20%')
                    "
                  >
                    <div
                      v-if="
                        data.architectureDiagrams &&
                        data.architectureDiagrams.length > 0
                      "
                      class="p1"
                      style="width: 100%"
                    >
                      {{ $t("share.pic") }} ( {{ current[data.id] + 1 }} /
                      {{ data.architectureDiagrams.length }})
                    </div>
                    <el-carousel
                      v-if="
                        data.architectureDiagrams &&
                        data.architectureDiagrams.length > 0
                      "
                      :ref="'carousel' + data.id + '@@@@'"
                      style="width: 100%; margin-top: 15px"
                      :autoplay="false"
                      @change="changge(data.id, $event)"
                    >
                      <el-carousel-item
                        v-for="item in data.architectureDiagrams"
                        :key="item.id"
                      >
                        <el-image
                          style="max-height: 90%; width: 100%"
                          :style="is_over3 ? 'filter: blur(5px);' : ''"
                          :src="item.data"
                          :preview-src-list="[item.data]"
                        >
                        </el-image>
                        <!-- <img
                          :src="item.data"
                          alt=""
                          
                          style="max-height: 90%; width: 100%"
                        /> -->
                      </el-carousel-item>
                    </el-carousel>

                    <div
                      v-if="
                        !data.architectureDiagrams ||
                        data.architectureDiagrams.length == 0
                      "
                      class="p1"
                    >
                      {{ $t("share.pic") }}
                    </div>

                    <div
                      v-if="
                        !data.architectureDiagrams ||
                        data.architectureDiagrams.length == 0
                      "
                      class="desc"
                    >
                      <div
                        class="desc_content"
                        :style="is_over3 ? 'filter: blur(5px);' : ''"
                      >
                        {{ $t("share.nodata") }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </el-tree>
          </div>
        </div>
      </div>
    </div>
    <div v-if="!show_file" class="nofile">
      <div>
        <p>{{ $t("share.s1") }}</p>
        <el-button type="primary" size="small" @click="notice">{{
          $t("share.s2")
        }}</el-button>
      </div>
    </div>

    <el-dialog
      :title="$t('share.save')"
      width="25%"
      :close-on-click-modal="false"
      :show-close="true"
      :visible.sync="saveDialogVisible"
      append-to-body
      :before-close="close_dai"
    >
      <el-select
        v-model="select_project"
        :placeholder="$t('share.pro')"
        style="width: 100%; margin-bottom: 25px"
      >
        <el-option
          v-for="item in project_options"
          :key="item.projectId"
          :label="item.projectName"
          :value="item.projectId"
        >
        </el-option>
      </el-select>

      <el-select
        v-if="select_project.length > 0"
        v-model="select_file"
        :placeholder="$t('share.file')"
        style="width: 100%; margin-bottom: 25px"
      >
        <el-option
          v-for="item in file_options"
          :key="item.fileTypeId"
          :label="item.name"
          :value="item.fileTypeId"
        >
        </el-option>
      </el-select>

      <span slot="footer" class="dialog-footer">
        <el-button type="info" size="small" @click="close_dai">{{
          $t("btn.cancelBtn")
        }}</el-button>
        <el-button type="primary" size="small" @click="save_file">{{
          $t("btn.confirmBtn")
        }}</el-button>
      </span>
    </el-dialog>
    <el-dialog
      :title="$t('share.ti')"
      width="25%"
      :close-on-click-modal="false"
      :show-close="true"
      :visible.sync="tipDialogVisible"
      append-to-body
    >
      <div style="font-size: 16px; text-align: start">
        {{ tip_text }}
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button type="info" size="small" @click="tipDialogVisible = false">{{
          $t("btn.cancelBtn")
        }}</el-button>
        <el-button type="primary" size="small" @click="to_login">{{
          $t("btn.saveBtn")
        }}</el-button>
      </span>
    </el-dialog>

    <!-- 是否已有账户 -->
    <el-dialog
      :title="$t('addMindMap.saveMindmapMoveDialogTitle')"
      width="25%"
      :close-on-click-modal="false"
      :show-close="true"
      :visible.sync="moveDialogVisible"
      append-to-body
    >
      <span slot="footer" class="dialog-footer">
        <el-button type="info" size="small" @click="hasAccount">{{
          $t("addMindMap.hasAccount")
        }}</el-button>
        <el-button type="primary" size="small" @click="noAccount">{{
          $t("addMindMap.noAccount")
        }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
// import { getFile } from "@/network/home/index.js";
import language from "@/components/language";
import { download_drawio } from "@/network/fileOperation/index.js";
import { getToken } from "@/utils/auth";
import { request_joinedProjects } from "@/network/personal/index.js";
import {
  getFile,
  save_file,
  get_status,
  viewRequestNotify,
  downloadword,
} from "@/network/share/index.js";
import { get_filetype_List } from "@/network/fileType/index.js";

export default {
  name: "Lineword",
  components: {
    language,
  },
  data() {
    return {
      f5: 0,
      show_file: true,
      data: {},
      is_over3: false,
      tip_text: "",
      node: {},
      show_top: true,
      topic: "",
      tipDialogVisible: false,
      itemdata: "",
      once: [],
      current: {},
      visiblelist: false,
      showCollapse: false,
      original_data: [],
      show_detail_map: {},
      edit_map: {},
      timer: null,
      collapse_button_text: this.$t("canvas.wordView.unfoldBtn"),
      collapse_flag: false,
      basedatalength: 0,
      current_key: "",
      current_node: [],
      handle_node: {},
      is_login: false,
      client: null,
      // ai补充
      payDialog_isShow: false,
      drawer: false,
      drawer_loading: false,
      // 被锁定的基线列表
      file_icon_name: "",
      file_icon: "",
      is_show_name: true,
      is_show_topic: true,
      node_name_span: true,
      input_name: "",
      workflow_dialog_visible: false,
      membersShow: false,
      members: [],
      rootVisible: false,
      nodeVisible: false,
      childVisible: false,
      caseVisible: false,
      caseword: "",
      is_edit: false,
      index_map: {},
      review_map: {},
      process_review_map: {},
      f6: 0,
      f7: 0,
      show_reject_form: false,
      reject_form: {},
      see_review: false,
      process_review: false,
      user_review_list: [],
      show_review_num: false,
      review_list: [],
      review_num_process: 0,
      review_num_all: 0,
      is_show_no_review_nodes: false,
      review_detail_show: false,
      review_detail_data: {},
      review_detail_list: [],
      outstanding_detail_dialog_show: false,
      outstanding_map: {},
      outstanding_type: "",
      outstanding_name: "",
      otherFile_map: {},
      only_show_node: false,
      // 保存编辑节点的值
      input_value: "",
      saveDialogVisible: false,
      project_options: [],
      select_project: "",
      select_file: "",
      file_options: [],
      moveDialogVisible: false,
    };
  },
  computed: {
    tree_data_line_word() {
      const arr = [];
      function search_children(data, root) {
        const items = [];
        for (let i = 0; i < data.length; i++) {
          if (root == data[i].parentid) {
            data[i].children = search_children(data, data[i].id);
            const temp_item = Object.assign(data[i], {
              label: data[i].topic,
              attachments: data[i].attachments,
            });
            items.push(temp_item);
          }
        }
        return items;
      }
      this.original_data.forEach((item) => {
        if (item.isroot) {
          arr.push(
            Object.assign(item, {
              label: item.topic,
              attachments: item.attachments,
            })
          );
          arr[0].children = search_children(this.original_data, "root");
        }
      });
      return arr;
    },
  },

  watch: {
    select_project() {
      get_filetype_List(this.select_project).then((res) => {
        this.file_options = res;
        this.select_file = "";
      });
    },
    current_node() {},
    tree_data_line_word() {
      let d =
        this.tree_data_line_word.length > 0
          ? this.tree_data_line_word[0].children
          : [];
      let m = {};
      m["root"] = "1";
      d = JSON.parse(JSON.stringify(d));
      while (d.length > 0) {
        let n = d.shift();
        if (n.layer < 5) {
          m[n.id] = m[n.parentid] + "." + n.index;
          d = [...d, ...(n.children || [])];
        }
      }
      this.index_map = m;
      this.f6 += 1;
    },
  },
  mounted() {
    this.is_login = getToken() ? true : false;

    let system = {
      win: false,
      mac: false,
      xll: false,
    };
    let p = navigator.platform;
    system.win = p.indexOf("Win") == 0;
    system.mac = p.indexOf("Mac") == 0;
    system.x11 = p == "X11" || p.indexOf("Linux") == 0;
    if (system.win || system.mac || system.xll) {
    } else {
      this.$router.push({
        path: `/m/pubWordview/` + this.$route.params.pathId,
      });
    }
    this.get_file_data();
    this.bindMoveLine();
  },
  beforeDestroy() {},
  methods: {
    to_web() {
      window.open("https://www.ytdevops.com");
    },
    close_dai() {
      this.saveDialogVisible = false;
      this.select_project = "";
      this.select_file = "";
    },
    notice() {
      if (getToken()) {
        viewRequestNotify(this.$route.params.pathId).then((res) => {
          this.$message({
            message: this.$t("share.send"),
            type: "success",
            duration: "5000",
          });
        });
      } else {
        sessionStorage.setItem("backURL", this.$route.path);

        this.$router.push({
          path: `/login`,
        });
      }
    },
    save_file() {
      if (this.select_project.length < 1) {
        this.$message({
          message: this.$t("share.pro"),
          type: "error",
          duration: "5000",
        });
        return;
      }
      if (this.select_file.length < 1) {
        this.$message({
          message: this.$t("share.file"),
          type: "error",
          duration: "5000",
        });
        return;
      }
      let da = {
        createFileDto: {
          fileTypeId: this.select_file,
          name: this.topic,
          testCase: "",
          testPlanId: "",
        },
        shareId: this.$route.params.pathId,
      };
      save_file(this.select_project, da).then((re) => {
        this.$router.push({
          path: `/${this.select_project}/myMind/${re}`,
        });
      });
    },
    to_login() {
      this.tipDialogVisible = false;
      if (getToken()) {
        request_joinedProjects().then((res) => {
          this.project_options = res;
          this.saveDialogVisible = true;
        });
      } else {
        sessionStorage.setItem("backURL", this.$route.path);
        this.moveDialogVisible = true;
      }
    },
    hasAccount() {
      sessionStorage.setItem("downloadword", this.$route.params.pathId);
      this.$router.push({
        path: `/login`,
      });
    },
    noAccount() {
      sessionStorage.setItem("downloadword", this.$route.params.pathId);
      this.$router.push({
        path:
          `/login/register?inviteMark=share-` + `${this.$route.params.pathId}`,
      });
    },
    switch_on() {
      this.show_top = !this.show_top;
      document.getElementsByClassName("line_word")[0].style["height"] =
        "calc(95vh - 60px)";
    },
    switch_off() {
      this.show_top = !this.show_top;
      document.getElementsByClassName("line_word")[0].style["height"] =
        "calc(95vh - 160px)";
    },
    changge(a, b) {
      this.$set(this.current, a, b);
    },
    to_word() {
      this.$router.push({
        path: `/pubMindmap/` + this.$route.params.pathId,
      });
    },
    visibilityChange(event) {
      const ev = event.target;
      const ev_weight = ev.scrollWidth; // 文本的实际宽度   scrollWidth：对象的实际内容的宽度，不包边线宽度，会随对象中内容超过可视区后而变大。
      const content_weight = ev.clientWidth; // 文本的可视宽度 clientWidth：对象内容的可视区的宽度，不包滚动条等边线，会随对象显示大小的变化而改变。
      if (ev_weight > content_weight) {
        // 实际宽度 > 可视宽度  文字溢出
        this.is_show_topic = false;
      } else {
        // 否则为不溢出
        this.is_show_topic = true;
      }
    },
    //判断三行出...
    isShowTooltip(e, refname) {
      const clientHeight = e.target.clientHeight;
      const scrollHeight = e.target.scrollHeight;
      const pWidth = refname
        ? this.$refs[refname].parentNode.offsetWidth
        : e.target.children[0].offsetWidth;
      const cWidth = refname
        ? this.$refs[refname].parentNode.offsetWidth
        : e.target.children[0].offsetWidth;
      // 文字超出隐藏并弹出tooltip提示，文字未超出则不弹出tooltip提示的判断条件
      if (scrollHeight > clientHeight && pWidth >= cWidth) {
        this.is_show_name = false;
        this.is_show_topic = false;
      } else {
        this.is_show_name = true;
        this.is_show_topic = true;
      }
    },
    //绑定中间的拉伸条
    bindMoveLine() {
      const resizeFn = (element, position) => {
        let maxAllowWidth = window.innerWidth / 2;
        let resize = document.querySelector(".resize");
        resize.style[position] = "0";
        resize.addEventListener("mousedown", function (e) {
          let startX = e.pageX;
          let width = element.offsetWidth;

          resize.classList.add("active");
          document.addEventListener("mousemove", move);
          let timer = null;

          function move(e) {
            let moveX; // 左右这里切换计算顺序即可
            if (position === "left") moveX = startX - e.pageX;
            if (position === "right") moveX = e.pageX - startX;
            clearTimeout(timer);
            timer = setTimeout(() => {
              // element.style.maxWidth = width + moveX + "px";
              if (maxAllowWidth >= width + moveX)
                element.style.minWidth = width + moveX + "px";
            }, 5);
          }

          document.addEventListener(
            "mouseup",
            function () {
              document.removeEventListener("mousemove", move);
              resize.classList.remove("active");
            },
            { once: true }
          );
        });
      };
      let left = document.querySelector(".catalog_wrap");
      resizeFn(left, "right");
    },
    des_show(data, flag) {
      data.forEach((item) => {
        this.des_show_all(item, flag);
        if (item.children.length) {
          this.des_show(item.children, flag);
        }
      });
    },
    expand_all(event) {
      if (event.target.nodeName === "SPAN") {
        event.target.parentNode.blur();
      } else {
        event.target.blur();
      }
      this.collapse_button_text =
        this.collapse_button_text === this.$t("canvas.wordView.unfoldBtn")
          ? this.$t("canvas.wordView.foldBtn")
          : this.$t("canvas.wordView.unfoldBtn");
      this.collapse_flag = !this.collapse_flag;
      for (let i = 0; i < this.$refs.myTree.store._getAllNodes().length; i++) {
        this.$refs.myTree.store._getAllNodes()[i].expanded = true;
      }
      this.des_show(this.tree_data_line_word, this.collapse_flag);
      //   this.get_file_data();
    },
    get_file_data() {
      function over3day(dateString) {
        const targetDate = new Date(dateString);
        const currentDate = new Date();
        const diff = currentDate - targetDate;
        const daysDiff = Math.ceil(diff / (1000 * 60 * 60 * 24));
        return daysDiff > 3;
      }

      get_status(this.$route.params.pathId).then((data) => {
        let is_read =
          sessionStorage.getItem(data.fileKey) &&
          sessionStorage.getItem(data.fileKey) == "has";
        if (over3day(data.createdAt) && !is_read) {
          this.tipDialogVisible = true;
          sessionStorage.setItem(data.fileKey, "has");
          this.tip_text = this.$t("share.d3");
          this.is_over3 = true;
        }
        if (data.effective && data.settingEffective) {
          const get_file = getFile(this.$route.params.pathId);

          Promise.all([get_file]).then((result) => {
            this.topic = result[0].meta.name;
            for (let i of result[0].data) {
              let basedata = [];
              if (i.architectureDiagrams) {
                i.architectureDiagrams.forEach(async (ele) => {
                  let res = await download_drawio(ele.data);
                  const reader = new FileReader();
                  reader.readAsText(res);
                  reader.onload = function (e) {
                    basedata.push({
                      id: ele.id,
                      name: ele.name,
                      data: e.target.result,
                    });
                  };
                });
              }

              i.architectureDiagrams = basedata;
            }

            result[0].data.sort(function (m, n) {
              if (m.index < n.index) return -1;
              else if (m.index > n.index) return 1;
              else return 0;
            });
            this.original_data = result[0].data;
            if (
              getToken() &&
              sessionStorage.getItem("downloadword") &&
              sessionStorage.getItem("downloadword") ==
                this.$route.params.pathId
            ) {
              downloadword(this.$route.params.pathId).then(() => {
                this.$router.push({
                  path: "/home",
                  query: {
                    show_task: true,
                  },
                });
              });
              sessionStorage.removeItem("downloadword");
            }
          });
        } else {
          this.show_file = false;
        }
      });
    },
    des_show_all(data, flag) {
      this.$set(this.show_detail_map, data.id, flag);
      this.$set(this.current, data.id, 0);
      if (this.$refs["carousel" + data.id + "@@@@"]) {
        this.$refs["carousel" + data.id + "@@@@"].setActiveItem(0);
      }
    },
    show_detail(data, flag) {
      this.$set(this.current, data.id, 0);
      if (this.$refs["carousel" + data.id + "@@@@"]) {
        this.$refs["carousel" + data.id + "@@@@"].setActiveItem(0);
      }

      const self = this;
      clearTimeout(self.timer); //清除未执行的定时器
      this.$refs.myTree.setCurrentKey(data.id);
      self.timer = setTimeout(function () {
        self.$set(
          self.show_detail_map,
          data.id,
          flag ? true : !self.show_detail_map[data.id]
        );
      }, 0);
    },

    // 点击目录部分
    handleNodeClick(data) {
      for (let i = 0; i < this.$refs.myTree.store._getAllNodes().length; i++) {
        if (this.$refs.myTree.store._getAllNodes()[i].data.id == data.id) {
          let p = this.$refs.myTree.store._getAllNodes()[i].parent;
          while (p) {
            p.expanded = true;
            p = p.parent;
          }
        }
      }
      this.show_detail(data, true);
      setTimeout(() => {
        if (data.id) {
          const rollDom = document.getElementById(data.id);
          // rollDom.scrollIntoView({ behavior: "smooth", block: "nearest" });
          //  block: 'nearest', inline: 'nearest',
          rollDom.scrollIntoView({
            block: "start",
            inline: "nearest",
            behavior: "smooth",
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  padding: 0 20px;
  .topic {
    height: 50px;
    color: rgb(44, 62, 80);
    font-size: 20px;
    display: flex;
    align-items: center;
  }
  .operate {
    font-size: 16px;
    height: 40px;
    display: flex;
    align-items: center;
    color: #808080;
    justify-content: space-between;
    border-bottom: 1px solid #d1d5de;
  }
  padding-bottom: 10px;
}
.line_word_wrap {
  text-align: start;

  ::v-deep .el-tree-node__content {
    height: fit-content;
    align-items: baseline;
    // padding: 10px 0;
  }

  .line_word_content {
    display: flex;
    //justify-content: space-around;
    .catalog_wrap {
      //width: 300px; /*no*/
      width: 150px;
      max-width: 50vw;
      overflow-x: auto;
      // height: calc(100vh - 200px);
      overflow-y: scroll;
      padding-left: 10px;
      border-right: 4px solid #f0f2f5;

      &::-webkit-scrollbar {
        //width: 6px;
        width: 0px;
        height: 0px;
        background-color: transparent;
      }

      &::-webkit-scrollbar-thumb {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #ccc;
        border-radius: 4px;
      }

      &::-webkit-scrollbar-track {
        border-radius: 3px;
        background: rgba(255, 255, 255, 1);
      }

      ::v-deep.el-tree-node.is-current > .el-tree-node__content {
        color: rgba(42, 130, 228, 1);
      }
    }
  }

  /*拖拽区div样式*/
  .resize {
    cursor: e-resize;
    min-width: 6px;
    font-size: 32px;
    user-select: none;
  }

  .resize.active {
    background-color: #0090f1;
  }

  .resize:hover {
    background-color: #0090f1;
  }
  #line_word_right {
    width: 100%;
    // margin-bottom: 100px;
  }
  .line_word {
    //width: calc(100% - 300px);
    flex: 1;
    overflow-y: scroll;
    height: calc(95vh - 160px);

    &::-webkit-scrollbar {
      width: 6px;
      height: 8px;
      background-color: #ebeef5;
    }

    &::-webkit-scrollbar-thumb {
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: #ccc;
      border-radius: 4px;
    }

    &::-webkit-scrollbar-track {
      border-radius: 3px;
      background: rgba(255, 255, 255, 1);
    }
  }

  .node_topic {
    max-width: 1000px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 18px;
    font-weight: 500;
    color: rgba(128, 128, 128, 1);
    text-indent: 16px;
    text-align: left;
    margin-right: 50px;

    &:hover {
      color: rgba(48, 100, 143, 1);
    }
  }

  .custom-tree-node {
    height: fit-content; /*no*/
    padding: 18px 0 0 30px;
    width: 100%;
    display: flex;
    justify-content: space-between;

    & > div {
      // width: 50%;
      padding: 10px;
    }
  }
}

.line_word {
  padding: 10px 42px 40px 42px;
}
.switch_on {
  margin-top: -10px;
  cursor: pointer;
  &:hover {
    background-color: #f9fafc;
    color: #409eff;
  }
}
.switch_off {
  // margin-top: -10px;
  cursor: pointer;
  &:hover {
    background-color: #f9fafc;
    color: #409eff;
  }
}
.con {
  width: 95%;
  height: 500px;

  .el-carousel__container {
  }
}

.language-option {
  position: absolute;
  top: 20px;
  right: 20px;
}
.left-header {
  background: linear-gradient(
    90deg,
    rgba(0, 76, 170, 1) 34%,
    rgba(3, 122, 188, 1) 100%
  );
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: left;
  align-items: center;
  .topbar-logo {
    padding-left: 10px;
    padding-right: 17px;
    padding-top: 7px;
    max-height: 100%;
    max-width: 100%;
  }
  .name_logo {
    width: 231px;
    height: 36px;
  }
}
.desc_content {
  word-break: break-all;
  max-width: 100%;
  white-space: normal;
  text-align: left;
  font-size: 16px;
  color: #808080;
}
.p1 {
  font-size: 18px;
  font-weight: 500;
  text-align: left;
  margin: 0px 0 10px 0;
  color: rgb(56, 56, 56);
}
.nofile {
  height: 90vh;
  display: flex;
  align-items: center;
  justify-content: center;
  p {
    font-size: 20px;
  }
}
</style>

<style lang="scss">
.con {
  .el-carousel__container {
    height: 100%;
  }
}

/* 隐藏滚动条并允许内容滚动 */
.scrollable-content {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  overflow-y: scroll; /* All other browsers */
}

/* 隐藏滚动条的外观 */
.scrollable-content::-webkit-scrollbar {
  width: 0px;
  background-color: transparent;
}
</style>
